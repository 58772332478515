import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { faPencilAlt, faTrash  } from '@fortawesome/free-solid-svg-icons';

import { DeleteRealizationService } from '../services/realization/delete-realization.service';

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { RealizationService } from '../services/realization/realization.service';
import { ErrorCatchService } from '../services/error-catch/error-catch.service';

@Component({
  selector: 'app-realization',
  templateUrl: './realization.component.html',
  styleUrls: ['./realization.component.less']
})
export class RealizationComponent implements OnInit {
  public dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  public realizations: any;
  public dataSource: any;

  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  

  constructor(private HttpClient: HttpClient, private DeleteRealizationService: DeleteRealizationService, private RealizationService: RealizationService, private ErrorCatchService: ErrorCatchService) { 
  
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false, 
      columnDefs: [ 
        { orderable: false, targets: [4,5,6] }
        ],
      language: {
          "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Czech.json"
        },
      }; 
      this.getRealizations();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    }
  
    onClickDelete(realizationID,realizationTitle){
      if(confirm("Chystáte se smazat realizaci: " + realizationTitle + ". ID realizace: "+ realizationID)){
        //console.log("on click delete" + pageTitle + "id:" + pageID);
        this.deleteNews(realizationID);
      } else {
        return false;
      };
      
    }
  
    deleteNews(realizationID){
      var formData: any = {};
      formData['realization_id'] = realizationID;   
      const promise =  this.DeleteRealizationService.process(formData);
      promise.then(
        //result => this.rerender()
        result => console.log("rerender")
      );
    }
  
    getRealizations(){
      this.RealizationService.getRealizationsData().subscribe(response => {
        this.realizations = response;
        this.dtTrigger.next();
      }, error => { 
        this.ErrorCatchService.getToastr(error.error.message);
      });
    }

}
