import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { LoginService } from '../services/login/login.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})

export class LoginComponent implements OnInit {

  loginForm = new FormGroup({
    loginAccount: new FormControl(),
    loginPassword: new FormControl(),
  });

  

  constructor(private LoginService: LoginService) { }

  ngOnInit(): void {    

  }

  onClickLogin(){
    var formData: any = new FormData();
    formData = this.loginForm.value;   
    this.LoginService.login(formData);
  }



}
