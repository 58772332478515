import { Component, OnInit } from '@angular/core';
import { faImages, faChild, faUser, faQuestion, faAddressBook, faCogs, faCalendarAlt, faDollarSign, faUsers, faFilePdf, faArchive, faChartBar, faNewspaper, faFile  } from '@fortawesome/free-solid-svg-icons';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from "@angular/router";

import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService();

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.less']
})
export class MainComponent implements OnInit {

  faAddressBook = faAddressBook;
  faQuestion = faQuestion;
  faCogs = faCogs;
  faCalendarAlt= faCalendarAlt;
  faDollarSign = faDollarSign;
  faUsers = faUsers;
  faFilePdf = faFilePdf;
  faArchive = faArchive;
  faChartBar = faChartBar;
  faNewspaper = faNewspaper;
  faFile = faFile;
  faUser = faUser;
  faChild = faChild;
  faImages = faImages;

 

  constructor(private router: Router, private toastr: ToastrService, private translate: TranslateService) { }
  public account: string;

  ngOnInit(): void {
    const token = localStorage.getItem('token');
    const tokenPayload = helper.decodeToken(token);
    this.account = tokenPayload.accountName; 
  }

  onClickLogout() {
    localStorage.removeItem('token');
    const translatedString = this.translate.instant('login.logout');
    this.toastr.success(translatedString);
    this.router.navigate(['login']);
  }

}
