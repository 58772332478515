
	<!-- A sidebar -->
    <div id="sidebar">
      
      <div class="sidebar-header">
        <div class="row">
          <div class="col-12 text-center helper-104"><img src="assets/img/logo.png"></div>
        </div>

        <div class="row mt-2">
            <div class="col-12 text-center color-lg f18">APIOM <span class="font-italic f12">CMS</span></div>
        </div>
      </div>
      
      <div class="sidebar-nav list-group list-group-flush">

        <div class="menu-head f12  mb-3 mt-4">CMS</div>
        <a [routerLink]="['/realization']"><fa-icon [icon]="faNewspaper" class="icon"></fa-icon>Realizace</a>
        <a [routerLink]="['/gallery']"><fa-icon [icon]="faImages" class="icon"></fa-icon>Galerie</a>

        <!--
        <div class="menu-head f12  mb-3 mt-4">Apiom</div>
        <a href="#"><fa-icon [icon]="faCogs" class="icon"></fa-icon>Nastavení</a>
        <a href="#"><fa-icon [icon]="faQuestion" class="icon"></fa-icon>Technická podpora</a>
        -->
      </div>

    </div>


	<!-- Page content -->
    <div id="page-content-wrapper">

      <div class="topnav-container">


            


            <form>
                <input type="text" placeholder="Hledaný výraz" name="search" autocomplete="off">
            </form>



            


            <div class="btn-group account" dropdown placement="bottom right">
                <a id="button-alignment" dropdownToggle type="button"
                        class="btn nav-link dropdown-toggle" aria-controls="dropdown-alignment">
                        <img src="assets/img/logo.png"> {{ account }} <span class="caret"></span>
                </a>
                <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right"
                    role="menu" aria-labelledby="button-alignment">
                  <li role="menuitem"><a class="dropdown-item" href="#">Nastavení účtu</a></li>
                  <li role="menuitem"><a class="dropdown-item " (click)="onClickLogout()">Odhlásit se</a></li>
                </ul>
            </div>


            
      </div>


      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </div>
	


