import { Injectable } from '@angular/core';
import { RequestService } from '../request/request.service';



@Injectable({
  providedIn: 'root'
})
export class DeleteRealizationService {
  private apiData: any;

  constructor(private RequestService: RequestService) { }
  url = "admin/realization/delete";

  process(formData){
    return new Promise(resolve => {
        this.apiData = this.RequestService.postRequest(this.url,formData);
        resolve('done');
    });
  }  
}
