<div class="row">
    <div class="col-sm crumbs">
      <a [routerLink]="['']">APIOM</a> \ <a [routerLink]="['/page']">GALERIE</a> \ <a href="#">UPRAVIT GALERII</a>
    </div>
</div>

<div class="row">
    <div class="col-sm title-2">
      Upravit Galerii
    </div>
</div>


<div id="content">
    <form class="form" [formGroup]="updateGalleryForm" (ngSubmit)="onClickUpdateGallery()">  
        <div class="row">
            <div class="col-12 col-sm-12 col-xl-9">
                <div class="form-group mr-xl-5">
                    <label for="main_img">Vyberte ID hlavní fotky</label>
                    <input class="c-input" type="number" id="main_img" name="main_img" formControlName="main_img">
                </div>   
            </div>
            <div class="col-12 col-sm-12 col-xl-3">     
                <div class="form-group text-center text-xl-left mt-5"> 
                    <button type="submit" class="add-new-item">Uložit změny</button>
                </div> 
            </div>
        </div>
    </form>

    <!-- (change)="fileChange($event)" -->
    <div class="row">
    <ul class="gallery">
        <li *ngFor="let image of galleryData.images;">
            <img src="https://rekonstrukcenedbalkova.cz/{{ image.thumb_path }}">
            {{ image.img_id }}   
        </li>
    </ul>
    </div>


    <form class="form" [formGroup]="uploadImagesForm" (ngSubmit)="uploadFiles()">  
        <div class="row">
            <div class="col-12 col-sm-12 col-xl-9">
                <div class="form-group mr-xl-5">
                    <label for="file">Vyberte obrázky k nahrání</label>
                    <input class="c-input" type="file" id="file" name="upload_file" formControlName="upload_file" (change)="selectFiles($event)" multiple>
                </div>   
            </div>
    
            <div class="col-12 col-sm-12 col-xl-3">     
                <div class="form-group text-center text-xl-left mt-5"> 
                    <button type="submit" class="add-new-item">Přidat obrázky</button>
                </div> 
            </div>
        </div>
    </form>
</div>





