import { Injectable } from '@angular/core';
import { RequestService } from '../request/request.service';



@Injectable({
  providedIn: 'root'
})
export class GalleryService {
  private apiData: any;

  constructor(private RequestService: RequestService) { }

  getGalleriesData(): any{
    return this.RequestService.getRequest("admin/gallery");
  }  

}