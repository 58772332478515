import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';



@Injectable({
  providedIn: 'root'
})
export class ErrorCatchService {
  constructor(private toastr: ToastrService, private translate: TranslateService, private router: Router) { }

  getToastr(data){
    const translatedString = this.translate.instant(data);
    this.toastr.error(translatedString);
    this.router.navigate(['']);
  }  

}
