import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';

import { faPencilAlt, faTrash  } from '@fortawesome/free-solid-svg-icons';

import { DeleteGalleryService } from '../services/gallery/delete-gallery.service';

import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

import { GalleryService } from '../services/gallery/gallery.service';
import { ErrorCatchService } from '../services/error-catch/error-catch.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.less']
})
export class GalleryComponent implements OnInit {
  public dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  public galleries: any;
  public dataSource: any;

  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  

  constructor(private HttpClient: HttpClient, private DeleteGalleryService: DeleteGalleryService, private GalleryService: GalleryService, private ErrorCatchService: ErrorCatchService) { 
  
  }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      searching: false, 
      columnDefs: [ 
        { orderable: false, targets: [5] }
        ],
      language: {
          "url": "//cdn.datatables.net/plug-ins/9dcbecd42ad/i18n/Czech.json"
        },
      }; 
      this.getGalleries();
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    }
  
    onClickDelete(galleryID,galleryTitle){
      if(confirm("Chystáte se smazat galerii: " + galleryTitle + ". ID galerie: "+ galleryID)){
        //console.log("on click delete" + pageTitle + "id:" + pageID);
        this.deleteGallery(galleryID);
      } else {
        return false;
      };
      
    }
  
    deleteGallery(galleryID){
      var formData: any = {};
      formData['gallery_id'] = galleryID;   
      const promise =  this.DeleteGalleryService.process(formData);
      promise.then(
        //result => this.rerender()
        result => console.log("rerender")
      );
    }
  
    getGalleries(){
      this.GalleryService.getGalleriesData().subscribe(response => {
        this.galleries = response;
        this.dtTrigger.next();
      }, error => { 
        this.ErrorCatchService.getToastr(error.error.message);
      });
    }

}
