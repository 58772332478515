<div class="row">
    <div class="col-sm crumbs">
      <a [routerLink]="['']">APIOM</a> \ <a href="#">GALERIE</a>
    </div>
</div>

<div class="row">
    <div class="col-sm title-2">
      Galerie
    </div>
    <div class="col-sm text-right">
        <button class="add-new-item" [routerLink]="['/gallery/create']">Přidat galerii</button>
    </div>
</div>

<div id="content">
  <div class="row">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
      <thead>
        <tr>
          <th>ID galerie</th>
          <th>Název</th>
          <th>Hlavní obrázek</th>
          <th>Datum vytvoření</th>
          <th>Datum poslední úpravy</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let gallery of galleries">
          <td>{{ gallery.gallery_id }}</td>
          <td>{{ gallery.name}}</td>
          <td>{{ gallery.main_img}}</td>
          <td>{{ gallery.dateTime }}</td>
          <td>{{ gallery.updateDateTime }}</td>
          <td class="text-right">
            <a class="table-link" [routerLink]="[gallery.gallery_id, 'edit']" ngbTooltip="Upravit galerii"><fa-icon [icon]="faPencilAlt" class="icon" ></fa-icon></a>
            <a class="table-link c-p" ngbTooltip="Smazat galerii" (click)="onClickDelete(gallery.gallery_id,gallery.title)"><fa-icon [icon]="faTrash" class="icon" ></fa-icon></a>         
          </td>
        </tr>
      </tbody>
      </table>
  </div>
</div>



