import { Injectable } from '@angular/core';
import { RequestService } from '../request/request.service';



@Injectable({
  providedIn: 'root'
})
export class RealizationService {
  private apiData: any;

  constructor(private RequestService: RequestService) { }

  getRealizationsData(): any{
    return this.RequestService.getRequest("admin/realization");
  }  

}