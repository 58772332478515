import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './services/auth/auth-guard.service';
import { RoleGuardService as RoleGuard } from './services/auth/role-guard.service';

import { LoginComponent } from './login/login.component';

import { MainComponent } from './main/main.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { RealizationComponent } from './realization/realization.component';
import { CreateRealizationComponent } from './create-realization/create-realization.component';
import { EditRealizationComponent } from './edit-realization/edit-realization.component';

import { GalleryComponent } from './gallery/gallery.component';
import { CreateGalleryComponent } from './create-gallery/create-gallery.component';
import { EditGalleryComponent } from './edit-gallery/edit-gallery.component';

const routes: Routes = [
  //{path: '', component: LoginComponent},
  {path: 'login', component: LoginComponent},
  {path: '', component: MainComponent, canActivate: [AuthGuard], canActivateChild: [AuthGuard],
  children: [
    {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
    {path: 'dashboard', component: DashboardComponent},
    
    {path: 'realization', component: RealizationComponent},
    {path: 'realization/create', component: CreateRealizationComponent},
    {path: 'realization/:realization/edit', component: EditRealizationComponent},

    {path: 'gallery', component: GalleryComponent},
    {path: 'gallery/create', component: CreateGalleryComponent},
    {path: 'gallery/:gallery/edit', component: EditGalleryComponent},

  ]},

];


@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class RoutesModule { }
