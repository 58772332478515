import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { VisitorInterceptor } from './interceptors/visitor.interceptor';

import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DataTablesModule } from 'angular-datatables';
import { NgxSummernoteModule } from 'ngx-summernote';


import { RoutesModule } from './routes.module';
import { JwtModule } from "@auth0/angular-jwt";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateLoader, TranslateModule} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';

import { NgxUiLoaderModule, NgxUiLoaderRouterModule, NgxUiLoaderHttpModule } from 'ngx-ui-loader';

import {
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION
} from 'ngx-ui-loader';


import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
import { DashboardComponent } from './dashboard/dashboard.component';

import { RealizationComponent } from './realization/realization.component';
import { EditRealizationComponent } from './edit-realization/edit-realization.component';
import { CreateRealizationComponent } from './create-realization/create-realization.component';
import { GalleryComponent } from './gallery/gallery.component';
import { CreateGalleryComponent } from './create-gallery/create-gallery.component';
import { EditGalleryComponent } from './edit-gallery/edit-gallery.component';


export function tokenGetter() {
  return localStorage.getItem("token");
}

const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  //bgsColor: 'red',
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  blur: 1000,
  bgsOpacity: 1,
  bgsColor: '#557757',
  fgsColor: '#557757',
  pbColor: '#557757',
  fastFadeOut: true,
  minTime: 350,
  overlayColor: 'rgba(21, 23, 26, 0.95)',
  bgsType: SPINNER.threeStrings, // background spinner type
  fgsType: SPINNER.threeStrings, // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5 // progress bar thickness
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MainComponent,
    DashboardComponent,

    RealizationComponent,
    EditRealizationComponent,
    CreateRealizationComponent,
    GalleryComponent,
    CreateGalleryComponent,
    EditGalleryComponent,

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RoutesModule,
    HttpClientModule,
    NgbModule,
    FontAwesomeModule,
    DataTablesModule,
    NgxSummernoteModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
      }
    }),
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-center',
      preventDuplicates: false,
    }),
    FormsModule,
    ReactiveFormsModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        //whitelistedDomains: ["example.com"],
        //blacklistedRoutes: ["example.com/examplebadroute/"]
      }
    }),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: VisitorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
