   <main class="login light-font">
    <div class="login-container bcg-dark-lg1 shadow vertical-horizontal-center">
        <div class="login">
            <div class="row">
                <div class="col-12 text-center mt-5"><img src="assets/img/logo.png"></div>
            </div>

            <div class="row mt-2">
                <div class="col-12 text-center color-lg f18">APIOM <span class="font-italic f12">CMS</span></div>
            </div>

            <form class="login p-5" [formGroup]="loginForm" (ngSubmit)="onClickLogin()">
                <div class="form-group mb-none">
                    
                    <input type="text" class="form-control" id="loginAccount" formControlName="loginAccount" autocomplete="off" placeholder=" ">
                    <label for="loginAccount" class="f14">Přihlašovací jméno</label>
                </div>
                <div class="form-group mb-none">               
                    <input type="password" class="form-control" id="loginPassword" formControlName="loginPassword" placeholder=" ">
                    <label for="loginPassword" class="f14">Heslo</label>
                </div>
                <button type="submit" class="btn py-3 f14">Přihlásit se</button>
            </form>

            <div class="row">
                <div class="col-12 text-center f14"><a href="#">Zapomenuté heslo</a></div>
            </div>

            
            <div class="row mt-2">
                <div class="col-12 text-center f14"><a href="#">Technická podpora</a></div>
            </div>
        </div>
    </div>
</main>
