import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

import 'rxjs/add/operator/map';
import 'rxjs/Rx';
import { map, catchError } from 'rxjs/operators';
import { Observable, of } from "rxjs";



@Injectable({
  providedIn: 'root'
})
export class RequestService {
  public apiData: any;
  public errorsData: any;
  public data;


  constructor(private HttpClient: HttpClient, private toastr: ToastrService, private translate: TranslateService, private router: Router) { }
 
  postRequest(url,postData){  
    this.HttpClient.post(url, postData)
    .subscribe(response => {
        this.apiData = response;
        const translatedString = this.translate.instant(this.apiData.message);
        this.toastr.success(translatedString);
    }, error => { 
        if(error.status == 422){
            this.errorsData = error.error.errors;
            for (var objectName in this.errorsData) {
                let array = error.error.errors[objectName];
                for (var value of array) {
                  const translatedString = this.translate.instant(value);
                  this.toastr.error(translatedString);
                }     
            }
            

            //const translatedString = this.translate.instant(error.errors.message);
            //this.toastr.error(translatedString);
        } else {
            const translatedString = this.translate.instant(error.error.message);
            this.toastr.error(translatedString);
        }
    })
    ;
  } 

  getRequest(url): any{
    return this.HttpClient.request('GET', url, {responseType:'json', headers: {'Authorization':'Bearer '+localStorage.getItem("token")}});
  }  

  /*
    getRequest(url) {
    this.HttpClient.request('GET', url, {responseType:'json', headers: {'Authorization':'Bearer '+localStorage.getItem("token")}}) .pipe(
        map(data => {
          console.log("data");
          console.log(data);
          return data;
        })
        )
        .subscribe(response => {
          this.apiData = response;
        }, error => { 
          const translatedString = this.translate.instant(error.error.message);
          this.toastr.error(translatedString);
          this.router.navigate(['']);
        });
  }  
  */
}

