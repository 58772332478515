<div class="row">
    <div class="col-sm crumbs">
      <a [routerLink]="['']">APIOM</a> \ <a [routerLink]="['/page']">GALERIE</a> \ <a href="#">PŘIDAT GALERII</a>
    </div>
</div>

<div class="row">
    <div class="col-sm title-2">
      Přidat galerii
    </div>
</div>


<div id="content">
    <form class="form" [formGroup]="createGalleryForm" (ngSubmit)="onClickCreateGallery()">  
    <div class="row">
        <div class="col-12 col-sm-12 col-xl-9">
            <div class="form-group mr-xl-5">
                <label for="name">Titulek</label>
                <input class="c-input" type="text" id="name" formControlName="name" autocomplete="off" placeholder="Titulek">
            </div>
        </div>

        <div class="col-12 col-sm-12 col-xl-3">
            <div class="form-group text-center text-xl-left mt-5"> 
                <button type="submit" class="add-new-item">Přidat galerii</button>
            </div> 
        </div>
    </div>
    </form>
</div>
