import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { EditGalleryService } from '../services/gallery/edit-gallery.service';
import { UploadImagesService } from '../services/gallery/upload-images.service';

@Component({
  selector: 'app-edit-gallery',
  templateUrl: './edit-gallery.component.html',
  styleUrls: ['./edit-gallery.component.less']
})
export class EditGalleryComponent implements OnInit {
  public galleryURL: string;
  public galleryData: any;

  selectedFiles: FileList;
  progressInfos = [];
  message = '';


  uploadImagesForm = new FormGroup({
    gallery_id: new FormControl(''),
    upload_file: new FormControl('')
  }
  );

  updateGalleryForm = new FormGroup({
    gallery_id: new FormControl(''),
    main_img: new FormControl('')
  }
  );

  constructor(private route: ActivatedRoute, private HttpClient: HttpClient, private EditGalleryService: EditGalleryService, private UploadImagesService: UploadImagesService) { }

  ngOnInit(): void {
    this.route.params.forEach(params => {
      let galleryURL = params["gallery"];
      this.galleryURL = galleryURL;
      this.getGallery(galleryURL);
    })
  }

  getGallery(galleryURL){
    this.HttpClient.request('GET', 'admin/gallery/' + galleryURL + '/galleryDetail,galleryImages', {responseType:'json'})
    .subscribe(response => {
      this.galleryData = response; 
      console.log(this.galleryData); 
      this.uploadImagesForm.patchValue({
        gallery_id: this.galleryData.gallery_id,
      });
      this.updateGalleryForm.patchValue({
        gallery_id: this.galleryData.gallery_id,
        main_img: this.galleryData.main_img,
      });
    });
  }

  fileChange(event) {
    let fileList: FileList = event.target.files;
    if(fileList.length > 0) {
        let file: File = fileList[0];
        let formData:FormData = new FormData();
        formData.append('file', file, file.name);
        formData.append('gallery_id', this.galleryURL);
        this.UploadImagesService.process(formData);
        console.log(formData);
    }
  }

  selectFiles(event) {
    this.selectedFiles = event.target.files;
  }

  uploadFiles() {
    
       for (let i = 0; i < this.selectedFiles.length; i++) {
      this.upload(i, this.selectedFiles[i]);
    }
    
   /*
    var formData: any = new FormData();
    formData.append('file', this.selectedFiles);
    console.log(formData);
    formData.append('gallery_id', this.galleryURL);
    this.UploadImagesService.process(formData);
    */
    
  }

  upload(idx, file) {
    var formData: any = new FormData();
    formData.append('file', file, file.name);
    formData.append('gallery_id', this.galleryURL);
    this.UploadImagesService.process(formData);
  }

  onClickUploadImages() { 
    var formData: any = new FormData();
    formData.append('file', this.uploadImagesForm.get('upload_file').value);
    console.log(formData);
    this.UploadImagesService.process(formData);
  }

  onClickUpdateGallery() { 
    var formData: any = new FormData();
    formData = this.updateGalleryForm.value; 
    //console.log(formData);
    this.EditGalleryService.process(formData);
  }

}
