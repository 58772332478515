import { Component } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import defaultLanguage from "./../assets/i18n/cs.json";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
  title = 'APIOM|CMS';
  constructor(private translate: TranslateService) {
    translate.setTranslation('cs', defaultLanguage);
    translate.setDefaultLang('cs');
  }
}
