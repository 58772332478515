<div class="row">
    <div class="col-sm crumbs">
      <a [routerLink]="['']">APIOM</a> \ <a href="#">REALIZACE</a>
    </div>
</div>

<div class="row">
    <div class="col-sm title-2">
      Realizace
    </div>
    <div class="col-sm text-right">
        <button class="add-new-item" [routerLink]="['/realization/create']">Přidat realizaci</button>
    </div>
</div>

<div id="content">
  <div class="row">
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover">
      <thead>
        <tr>
          <th>ID realizace</th>
          <th>Titulek</th>
          <th>Kategorie</th>
          <th>Velikost</th>
          <th>Datum vytvoření</th>
          <th>Datum poslední úpravy</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let realization of realizations">
          <td>{{ realization.realization_id }}</td>
          <td>{{ realization.title}}</td>
          <td>{{ realization.category_id }}</td>
          <td>{{ realization.size }}</td>
          <td>{{ realization.dateTime }}</td>
          <td>{{ realization.updateDateTime }}</td>
          <td class="text-right">
            <a class="table-link" [routerLink]="[realization.realization_id, 'edit']" ngbTooltip="Upravit realizaci"><fa-icon [icon]="faPencilAlt" class="icon" ></fa-icon></a>
            <a class="table-link c-p" ngbTooltip="Smazat realizaci" (click)="onClickDelete(realization.realization_id,realization.title)"><fa-icon [icon]="faTrash" class="icon" ></fa-icon></a>         
          </td>
        </tr>
      </tbody>
      </table>
  </div>
</div>


