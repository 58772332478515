<div class="row">
    <div class="col-sm crumbs">
      <a [routerLink]="['']">APIOM</a> \ <a [routerLink]="['/page']">NOVINKY</a> \ <a href="#">UPRAVIT REALIZACI</a>
    </div>
</div>

<div class="row">
    <div class="col-sm title-2">
      Upravit realizaci
    </div>
</div>


<div id="content">
    <form class="form" [formGroup]="updateRealizationForm" (ngSubmit)="onClickUpdateRealization()">  
        <div class="row">
            <div class="col-12 col-sm-12 col-xl-9">
                <div class="form-group mr-xl-5">
                    <label for="title">Titulek</label>
                    <input class="c-input" type="text" id="title" formControlName="title" autocomplete="off" placeholder="Titulek">
                </div>
                <div class="form-group mr-xl-5">
                    <label for="text">Text realizace</label>
                    <textarea class="form-control" name="notes" id="text" formControlName="text" [ngxSummernote]="text_config">
                    </textarea>
                </div>
                <div class="form-group mr-xl-5">
                    <label for="short_text">Perex realizace</label>
                    <textarea class="form-control" name="notes" id="short_text" formControlName="short_text" [ngxSummernote]="short_text_config">
                    </textarea>
                </div>
    
            </div>
    
            <div class="col-12 col-sm-12 col-xl-3">
                <div class="form-group">
                    <label for="url">URL adresa</label>
                    <input class="c-input" type="text" id="url" formControlName="url" autocomplete="off" placeholder="URL adresa">
                </div>
    
                <div class="form-group">
                    <label for="size">Velikost</label>
                    <input class="c-input" type="text" id="size" formControlName="size" autocomplete="off" placeholder="Velikost">
                </div>

                <div class="form-group">
                    <label for="price">Cena cca</label>
                    <input class="c-input" type="text" id="price" formControlName="price" autocomplete="off" placeholder="Cena cca">
                </div>

                <div class="form-group">
                    <label for="gallery_id">ID gallerie</label>
                    <input class="c-input" type="text" id="size" formControlName="gallery_id" autocomplete="off" placeholder="ID galerie">
                </div>
    
                <div class="form-group">
                    <label for="type">Kategorie</label>
                    <select name="type" id="type" formControlName="category_id" class="c-input">
                        <option *ngFor="let realizationCategory of realizationCategories" [value]="realizationCategory.type">{{ realizationCategory.label }}</option>
                        <!--
                        <option [value]="INFO" [selected]="'INFO'== newsData.type">Informační</option>
                        <option [value]="WARNING" [selected]="'WARNING'== newsData.type">Upozornění</option>
                        <option [value]="BUG" [selected]="'BUG'== newsData.type">Bug report</option>
                        -->
                      </select>
                </div>
    
    
                <div class="form-group text-center text-xl-left mt-5"> 
                    <button type="submit" class="add-new-item">Upravit realizaci</button>
                </div> 
            </div>
        </div>
    </form>
</div>




