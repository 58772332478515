import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { EditRealizationService } from '../services/realization/edit-realization.service';

@Component({
  selector: 'app-edit-realization',
  templateUrl: './edit-realization.component.html',
  styleUrls: ['./edit-realization.component.less']
})
export class EditRealizationComponent implements OnInit {
  public realizationURL: string;
  public realizationData: any;

  updateRealizationForm = new FormGroup({
    realization_id: new FormControl(''),
    title: new FormControl(''),
    size: new FormControl(''),
    price: new FormControl(''),
    text: new FormControl(''),
    short_text: new FormControl(''),
    url: new FormControl(''),
    category_id: new FormControl(''),
    gallery_id: new FormControl(''),
  }
  );

  text_config = {
    placeholder: 'Text realizace',
    tabsize: 2,
    height: '250px',
  }

  short_text_config = {
    placeholder: 'Perex reaizace',
    tabsize: 2,
    height: '100px',
  }

  realizationCategories = [
    { type: 1, label: "Rodinné domy" },
    { type: 2, label: "Byty 1+kk" },
    { type: 4, label: "Byty 2+kk" },
    { type: 5, label: "Byty 3+kk" },
    { type: 7, label: "Byty 4+kk" },

  ]

  constructor(private route: ActivatedRoute, private HttpClient: HttpClient, private EditRealizationService: EditRealizationService) { }

  ngOnInit(): void {
    this.route.params.forEach(params => {
      let realizationURL = params["realization"];
      //call your function, like getUserInfo()
      this.getNews(realizationURL);
    })
  }

  getNews(realizationURL){
    this.HttpClient.request('GET', 'admin/realization/' + realizationURL + '/realizationDetail', {responseType:'json'})
    .subscribe(response => {
      this.realizationData = response;  
      console.log(this.realizationData);
      this.updateRealizationForm.patchValue({
        realization_id: this.realizationData.realization_id,
        title: this.realizationData.title, 
        category_id: this.realizationData.category_id,
        gallery_id: this.realizationData.gallery_id,  
        url: this.realizationData.url, 
        text: this.realizationData.text,
        short_text: this.realizationData.short_text,
        size: this.realizationData.size,
        price: this.realizationData.price,
      });
    });
  }

  onClickUpdateRealization() { 
    var formData: any = new FormData();
    formData = this.updateRealizationForm.value; 
    //console.log(formData);
    this.EditRealizationService.process(formData);
  }

}
