import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import {Router} from "@angular/router";

import { JwtHelperService } from "@auth0/angular-jwt";

const helper = new JwtHelperService();


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiData: any;

  constructor(private HttpClient: HttpClient, private toastr: ToastrService, private translate: TranslateService, private router: Router) { }
  url = "admin/login";

  login(formData){
    
    this.HttpClient.post(this.url, formData)
    .subscribe(response => {
        this.apiData = response;
        const translatedString = this.translate.instant(this.apiData.message);
        this.toastr.success(translatedString);
        localStorage.setItem('token', this.apiData.token);
        this.router.navigate(['dashboard']);
    }, error => { 
        const translatedString = this.translate.instant(error.error.message);
        this.toastr.error(translatedString);
        //console.log(error.error.message);
    });

  }
  

}