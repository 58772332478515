import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class VisitorInterceptor implements HttpInterceptor {

  constructor(
    ) { }

 intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
     const cloned = req.clone({
         url: environment.apiUrl + req.url,
         withCredentials: true
     });

     const old = req.clone({
      url: req.url,
      withCredentials: true
    });
     
     if(req.url != '/assets/i18n/cs.json'){
        return next.handle(cloned);
     } else{
        return next.handle(old);
     }
     
     
  }
}
